import { graphql } from "gatsby";
import React from "react";
import Policy from "../components/privacy-policy";


const PrivacyPolicy = ({ data }) => {
  return <Policy policyData={data} />
}

export default PrivacyPolicy;

export const query = graphql`
 {
   contentfulPrivacyPolicy(htmlContents: {}) {
    title
    htmlContents {
      raw
    }
    image {
      fluid(maxWidth: 1000, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
   }
    contentfulCookie {
      cookieText {
       cookieText
      }
    }
    allContentfulNavigationSliderContent {
      nodes{
        title{
          title
        }
        redirectUrl,
        link
      }
    }
}
`;
