import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet';

const Policy = ({ policyData }) => {
    const { contentfulPrivacyPolicy } = policyData;
    const contentfulPrivacyPolicyRaw = JSON.parse(
        contentfulPrivacyPolicy?.htmlContents?.raw
    );
    return <Fragment>
        <Helmet>
            <title>Privacy Policy</title>
        </Helmet>
        <div className='banner-blue-background'>
            <div className="container">
                <div className="banner-wrapper">
                    <h1 className="heading">{contentfulPrivacyPolicy?.title}</h1>
                </div>
            </div>
        </div>
        <section className="private-policy-section" dangerouslySetInnerHTML={{ __html: contentfulPrivacyPolicyRaw?.content?.[0].content?.[0]?.value }} />
    </Fragment>
}

export default Policy;
